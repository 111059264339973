import React from 'react'
import { graphql } from 'gatsby'
import { getImage, getSrc } from 'gatsby-plugin-image'
import loadable from '@loadable/component'
import PageLayout from '../layouts/PageLayout'
import Section from 'components/Section/Section'
import Seo from 'components/Seo'
import SectionImage from 'components/Section/SectionImage'

const OrganizationData = loadable(() =>
  import('../components/StructuredData/OrganizationData')
)

const IndexPage = ({
  data: {
    markdownRemark: {
      frontmatter: { meta, sections = [] },
    },
  },
  location: { pathname },
}) => {
  const imagePath = getSrc(meta.ogImage)
  const svgArray = [
    null,
    { art: 'ulysses', x: 'left', y: 'middle', size: 'medium' },
    { art: 'budsketch', x: 'right', y: 'middle', size: 'large' },
    null,
  ]

  const sectionsMap = sections.map(
    ({ content, background, ctaButton }, index) => {
      const contentImage = getImage(content.contentImage)
      const backgroundImage = getImage(background.backgroundImage)
      return (
        <Section
          key={background.backgroundColor}
          index={index}
          fullHeight
          id={`page-section-${index}`}
          as={index === 0 ? 'header' : 'section'}
          svg={svgArray[index]}
          colorTheme={background.backgroundColor}
          backgroundImage={backgroundImage}
          colorOverlay={background.colorOverlay}
          content={content}
          ctaButton={ctaButton}
          pathname={pathname}
          image={
            <SectionImage
              image={contentImage}
              imagePosX={content.imagePosX}
              imagePosY={content.imagePosY}
            />
          }
        />
      )
    }
  )

  return (
    <PageLayout pathname={pathname}>
      <Seo
        title={meta.title}
        description={meta.metaDescription}
        ogImage={imagePath}
        meta={meta}
        pathname={pathname}
      />
      <OrganizationData />
      {sectionsMap}
    </PageLayout>
  )
}

export default IndexPage

export const query = graphql`
  query HomePageQuery {
    markdownRemark(frontmatter: { title: { eq: "index" } }) {
      id
      frontmatter {
        meta {
          title
          metaDescription
          ogImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        sections {
          content {
            header
            subheader
            posX
            posY
            ...SectionImageFragment
            imagePosX
            imagePosY
          }
          background {
            backgroundColor
            backgroundImage {
              childImageSharp {
                gatsbyImageData
              }
            }
            colorOverlay
          }
          ctaButton {
            buttonText
            buttonURL
            buttonAction
          }
        }
      }
    }
  }
`
